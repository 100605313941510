<template>
  <div id="privacy-terms">
    <h1 class="terms__title">隱私權保護</h1>
    <div class="terms__title-divider" />

    <h4>
      歡迎您使用布 ·
      可思益（以下簡稱「本平台」），我們非常重視您的隱私權，並致力於保護您的個人資料。本隱私權保護政策（以下稱「本政策」）說明了我們如何蒐集、使用、儲存和保護您的個人資料。
    </h4>

    <h3>一、蒐集的個人資料類型</h3>
    <p>
      1.
      註冊資訊：您在本平台註冊帳號時所輸入的姓名、電子郵件、電話號碼和郵寄地址等基本資訊。
    </p>
    <p>
      2.
      交易資訊：您在本平台進行購買或銷售時所填寫的交易相關資料，包括訂單詳細資訊、支付方式和配
      送資訊等。
    </p>
    <p>3. 技術資訊：包括IP地址、瀏覽器類型、操作系統、訪問時間和瀏覽行為等。</p>
    <p>4.客戶服務資訊：您與我們的客戶服務團隊聯繫時所提供的相關資訊。</p>

    <h3>二、個人資料的使用</h3>
    <p>
      1.
      提供服務：我們使用您的個人資料來處理您的訂單，管理您的帳戶並提供您所需的服務。
    </p>
    <p>2. 改善平台：我們會分析您的使用行為和反饋，以改進本平台的功能和服務。</p>
    <p>
      3.
      行銷推廣：在獲得您的同意後，我們可能會向您發送有關產品和優惠活動的資訊。
    </p>
    <p>
      4.
      安全保障：我們使用您的資料來防範和偵測詐欺、濫用和其他不法行為，並保障本平台的安全性。
    </p>

    <h3>三、個人資料的分享</h3>
    <p>
      我們不會將您的個人資料出售給第三方，但在以下情況下，我們可能會分享您的資料：
    </p>
    <p>1. 法律要求：當法律要求或應政府機關的要求，我們會提供您的資料。</p>
    <p>
      2.第三方服務提供商：我們可能會與提供支付處理、物流配送和數據分析等服務的第三方合作，這些第三方僅能在提供服務的範圍內使用您的資料。
    </p>
    <p>
      3.業務轉讓：在本平台發生合併、收購或資產轉讓時，您的資料可能會轉讓給新的經營者。
    </p>

    <h3>四、個人資料的儲存和保護</h3>
    <p>1. 儲存期限：我們僅在所需的期間內與法律要求的保留期間保存您的資料。</p>
    <p>
      2.安全措施：我們採取合理的技術和組織措施來保護您的個人資料免受未經授權的存取、使用或洩漏。
    </p>

    <h3>五、您的權利</h3>
    <p>
      1.
      查詢和更正：您有權查詢和更正您的個人資料，若需行使這些權利，請聯繫我們的客戶服務團隊。
    </p>
    <p>
      2.
      刪除：在某些情況下，您有權要求我們刪除您的個人資料，但法律另有規定或保留資料的原因除外。
    </p>
    <p>
      3.
      撤回同意：若您先前同意我們使用您的個人資料進行行銷活動，您可以隨時撤回同意。
    </p>

    <h3>六、Cookie</h3>
    <h4>
      本平台會在您的電腦上設定與存取cookie，您可以透過設定您的個人電腦或上網設備，決定是否准許使用cookie。若您關閉Cookie，可能會造成您使用本網站服務時全部或部分的功能限制。
    </h4>

    <h3>七、隱私權保護政策修訂</h3>
    <h4>
      本平台將會隨時依照法律規定修改與調整本政策，若您對本平台的隱私權聲明，或與個人資料有關之相關事項有任何疑問，歡迎隨時
      聯絡我們 ，我們會盡快協助您。
    </h4>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="stylus">
#privacy-terms
  padding 50px 0

.terms__title
  font-size $font-size-title
  color #4A9A8A
  padding 10px 0

.terms__title-divider
  height 5px
  background #957200
  border-radius 4px
  width 100%

p
  font-size $font-size-lg3x
  text-indent -40px
  font-weight 500
  line-height 30px
  margin-top 20px
  padding-left 50px

h3
  font-size $font-size-lg3x
  font-weight 700
  margin-top 50px

h4
  font-size $font-size-lg3x
  font-weight 500
  margin-top 20px
  text-indent 40px
</style>
